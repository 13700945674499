<template>
  <section id="github">
    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row align="center" justify="center">
            <v-col sm="4" class="hidden-xs-only">
              <v-img src="@/assets/img/ill2.svg" class="d-block ml-auto mr-auto" max-width="350px" />
            </v-col>
            <v-col cols="12" sm="8" class="white--text text-left">
              <h1 class="font-weight-light display-2 mb-5">Projekte auf Github</h1>
              <v-img contain height="250px" src="@/assets/img/2024-04-02_github-api.svg" />
              <v-btn rounded href="https://github.com/suhrmann" target="_blank" x-large color="white" class="mt-4">
                <v-icon class="mr-2">
                  mdi-github
                </v-icon>
                @suhrmann
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
}
</script>

<style scoped>
#github {
  background-image: url("~@/assets/img/bgDownload.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 500px;
}

#github .container,
#github .row {
  height: 100%;
}
</style>