<template>
  <v-footer dark padless>
    <v-card flat tile class="secondary white--text text-center">

      <v-card-text class="white--text">
        <ImprintDialog />
        <PrivacyDialog />
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="white--text">
        {{ new Date().getFullYear() }} — <strong>Simon Uhrmann Software Entwicklung</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<style scoped>
.v-card {
  width: 100%;
}
</style>

<script>
import ImprintDialog from "@/components/ImprintDialog.vue";
import PrivacyDialog from "@/components/PrivacyDialog.vue";

export default {
  components: {PrivacyDialog, ImprintDialog},
  data: () => ({
    icons: [
        /*
      {
        text: "mdi-facebook",
        link: "",
      },
      {
        text: "mdi-twitter",
        link: "",
      },
      {
        text: "mdi-linkedin",
        link: "",
      },
      {
        text: "mdi-instagram",
        link: "https://instagram.com/joabson_arley/",
      },
         */
    ],
  }),
};
</script>
