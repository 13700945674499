<template>
  <section id="about">
    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row align="center" justify="center">
            <v-col cols="12" md="7">
              <h1 class="font-weight-light display-2">Über</h1>
              <v-row>
                <v-col cols="12" class="d-flex align-center">
                  <v-img
                    src="@/assets/img/icon1.svg"
                    max-width="60px"
                    class="mr-4"
                  />
                  <p class="text-justify">
                    Individuell angepasste, preiswerte Softwarelösungen,
                    die perfekt auf die Bedürfnisse Ihres Unternehmens abgestimmt sind.
                    Entdecken Sie, wie unsere kostenoptimierten Lösungen Ihr Geschäft voranbringen können.
                  </p>
                </v-col>
                <v-col cols="12" class="d-flex align-center">
                  <v-img
                    src="@/assets/img/icon2.svg"
                    max-width="60px"
                    class="mr-4"
                  />
                  <p class="text-justify">
                    Wir arbeiten eng mit Ihnen zusammen, um sicherzustellen, dass wir genau die richtige Softwarelösung finden,
                    die zu Ihren spezifischen Anforderungen passt.
                    Lassen Sie uns gemeinsam den Weg zu optimierten Arbeitsabläufen und gesteigerter Produktivität beschreiten.
                  </p>
                </v-col>
                <v-col cols="12" class="d-flex align-center">
                  <v-img
                    src="@/assets/img/icon3.svg"
                    max-width="60px"
                    class="mr-4"
                  />
                  <p class="text-justify">
                    Entdecken Sie unsere Lösungen, die perfekt auf Ihre Bedürfnisse abgestimmt sind,
                    um sich nahtlos in Ihr bestehendes Geschäft zu integrieren.
                    Wir kümmern uns um den technischen Aspekt, sodass Sie sich auf Ihr Kerngeschäft konzentrieren können.
                  </p>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="5" class="d-none d-md-flex">
              <v-img
                src="@/assets/img/ill1.svg"
                class="d-block ml-auto mr-auto"
                max-width="400px"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
}
</script>

<style scoped>
#about {
  background-color: #f4f7f5;
}
</style>
