<template>
    <v-dialog
        v-model="dialog"
        width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            dark
            text
            small
            v-bind="attrs"
            v-on="on"
        >
          Impressum
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Impressum
        </v-card-title>

        <v-card-text>
          <h2>Angaben gem. §5 TMG</h2>

          <p>Simon Uhrmann<br />
            Simon Uhrmann Software Entwicklung<br />
            Am Scheibenacker 1<br />
            94078 Freyung</p>

          <h2>Kontakt</h2>
          <p>
            Telefon: +49 (0) 151 58 82 27 41<br />
            E-Mail: <a href="javascript:window.location.href=atob('bWFpbHRvOmluZm9Ac3Vocm1hbm4ub3Jn')">info &lt;at&gt; suhrmann &lt;dot&gt; org </a>
          </p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="dialog = false"
          >
            Schließen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      dialog: false,
    }
  },
}
</script>

<style scoped>
</style>